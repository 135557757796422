.footer {
  background-color: inherit;
  color: inherit;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 1s ease;
  z-index: 999;

  &.active {
    opacity: 1;
  }

  ul {
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
    font-size: 0.85rem;
    padding-block: 0.5rem;

    li {
      cursor: pointer;
    }

    & > * ~ * {
      margin-left: 2rem;
    }
  }
}
